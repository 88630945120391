import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const RatingGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon className={`rating-graphic ${className || ''} ${css(...(styles || []))}`} height={180} width={180}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='90' cy='90' r='90' fill='#CAE0EC' />
				<path
					fill='#FFF'
					d='M90,111.944945 C91.1135742,111.944945 92.2329183,112.262013 93.2022472,112.896148 L114.758275,127 L107.534467,102.178127 C106.951716,100.202116 107.517158,98.0732325 109,96.6181182 L128,77.9734039 L107.309444,77.9734039 C105.122684,77.9734039 103.126329,76.7617522 102.14546,74.8423601 L90,51 L77.8545399,74.8423601 C76.8736714,76.7617522 74.8773155,77.9734039 72.6905557,77.9734039 L52,77.9734039 L71,96.6181182 C72.4828424,98.0732325 73.0482842,100.202116 72.4655329,102.178127 L65.2417249,127 L86.7977528,112.896148 C87.7670817,112.262013 88.8864258,111.944945 90,111.944945 M124.199353,145.998857 C123.099274,145.998857 121.999194,145.678937 121.035913,145.039096 L90,124.301384 L58.9640874,145.039096 C56.9805249,146.364481 54.3756742,146.318778 52.4434108,144.901987 C50.5111474,143.490908 49.6618634,141.017236 50.3230509,138.71495 L60.7994526,101.958356 L34.6711471,75.7705682 C33.040978,74.1424008 32.5507873,71.6801545 33.4342705,69.5492547 C34.3177538,67.412642 36.3982145,66.0187021 38.7009709,66.0187021 L69.3777903,66.0187021 L84.8985965,34.9007088 C86.83086,31.0330971 93.16914,31.0330971 95.1014035,34.9007088 L110.62221,66.0187021 L141.299029,66.0187021 C143.601786,66.0187021 145.682246,67.412642 146.565729,69.5492547 C147.449213,71.6801545 146.959022,74.1424008 145.328853,75.7705682 L119.200547,101.958356 L129.676949,138.71495 C130.338137,141.017236 129.488853,143.490908 127.556589,144.901987 C126.559108,145.633234 125.37923,145.998857 124.199353,145.998857'
				/>
			</g>
		</SvgIcon>
	);
};
